<template>
  <div class="position-alarm">
    <div class="alarm-top">
      <van-nav-bar
        title="人员定位报警"
        left-arrow
        :border="false"
        @click-left="onClickLeft"
      >
      </van-nav-bar>
      <div ref="filterBoxWrapper" class="filter-box-wrapper">
        <van-search
          v-model="query.alarmLocation"
          placeholder="请输入报警围栏名称关键字"
          class="search-bar"
          @search="onSearch"
          @clear="onCancel"
        >
          <template #left-icon>
            <van-icon
              name="sousuo"
              class-prefix="iconfont"
              class="search-icon"
            />
          </template>
        </van-search>
        <div ref="filterBox" class="filter-box">
          <span
            v-for="(item, index) in filterList"
            ref="filterItems"
            :key="index"
            class="filter-item"
            :class="[item.type, { active: item.text }]"
            @click="selectCon(item.index, item)"
            >{{ item.text || item.name }}</span
          >
        </div>
        <div class="search-condition">
          <div class="alarm-total">共{{ total }}条结果</div>
        </div>
      </div>
    </div>

    <van-pull-refresh
      ref="vanList"
      v-model="refreshing"
      class="alarm-list"
      @refresh="onRefresh"
      @scroll.native="onScroll"
    >
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :error.sync="loadError"
        error-text="加载失败，请稍后再试！"
        @load="onLoad"
      >
        <div
          v-for="(item, index) in alarmRecords"
          :key="item.id + index"
          class="alarm-item"
          @click="gotoAlarmDetail(item.id)"
        >
          <div class="alarm-title">
            {{
              item.alarmLocation && item.alarmLocation.length > 25
                ? item.alarmLocation.slice(0, 25) + "..."
                : item.alarmLocation || "--"
            }}
            <span v-if="item.alarmStatus === 0" class="alarm-status warning"
              >正在报警</span
            >
            <span v-else class="alarm-status end">
              报警结束
            </span>
          </div>
          <div class="alarm-content">
            <div class="alarm-content_left">
              <div class="alarm-content_left-top">
                <div>
                  <i class="iconfont iconfont-fengxiandianleixing"></i
                  ><span>{{ item.applyTypeName }}</span>
                </div>
                <div>
                  <i class="iconfont iconfont-baojingyuzhi"></i
                  ><span>{{ item.alarmTypeName }}</span>
                </div>
              </div>
              <div class="alarm-content_left-bottom">
                <i class="iconfont iconfont-riqi"></i
                ><span>{{ item.ctime }}</span>
              </div>
            </div>
            <div class="alarm-content_right">
              <i
                v-if="item.dealStatus === 1"
                class="iconfont iconfont-yichuzhi"
              ></i>
              <i v-else class="iconfont iconfont-daichuzhi"></i>
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
    <SelectPopupGrade
      ref="selectPopupGrade"
      v-model="popupVisibleObj[activeIndex].value"
      :immediately="false"
      list-key="value"
      :title="popupVisibleObj[activeIndex].title"
      :list.sync="popupVisibleObj[activeIndex].list"
      :visible="popupVisibleObj[activeIndex].visible"
      @change="v => popupGradeChange(v, activeIndex)"
      @close="popupVisibleObj[activeIndex].visible = false"
    />
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import SelectPopupGrade from "@/components/SelectPopupGrade";
import {
  getSelectLocationAlarmType,
  getSelectLocationPersonType,
  getSelectLocationAlarmStatus,
  getSelectLocationDealStatus,
  getPageLocationAlarm
} from "@/api/alarm";

export default {
  name: "PositionAlarmList",
  components: {
    SelectPopupGrade
  },
  computed: {
    ...mapState({
      userInfo: state => state.login.userInfo,
      needRefreshList: state => state.alarm.needRefreshList
    })
  },
  data() {
    return {
      scTop: 0,
      departmentIds: [],
      departmentList: [],
      filterList: [
        {
          name: "类型",
          type: "select",
          index: 1,
          text: "",
          isActive: false
        },
        {
          name: "报警类型",
          type: "select",
          index: 2,
          text: "",
          isActive: false
        },
        {
          name: "报警状态",
          type: "select",
          index: 3,
          text: "",
          isActive: false
        },
        {
          name: "处置状态",
          type: "select",
          index: 4,
          text: "",
          isActive: false
        }
      ],
      queryMap: {
        1: "applyType",
        2: "alarmType",
        3: "alarmStatus",
        4: "dealStatus"
      },
      popupVisibleObj: {
        1: {
          visible: false,
          title: "类型",
          value: "",
          list: []
        },
        2: {
          visible: false,
          title: "报警类型",
          value: "",
          list: []
        },
        3: {
          visible: false,
          title: "报警状态",
          value: "",
          list: []
        },
        4: {
          visible: false,
          title: "处置状态",
          value: "",
          list: []
        }
      },
      loading: false,
      finished: false,
      loadError: false,
      refreshing: false,
      // 查询条件
      query: {
        size: 10,
        page: 1,
        alarmLocation: "",
        applyType: "",
        alarmType: "",
        alarmStatus: "",
        dealStatus: "",
        sort: "ctime_desc"
      },
      // 告警列表
      alarmRecords: [],
      total: 0,

      activeIndex: 1
    };
  },
  created() {
    this.scrollFrom = "list";
    this.lastScrollTop = 0;
    this.query.orgCode = this.userInfo.orgCode;
  },
  methods: {
    ...mapMutations({
      setNeedRefreshList: "setNeedRefreshList"
    }),
    popupGradeChange(v, type) {
      let item = this.filterList.find(s => s.index === type);
      item.text = (v && v[0]?.label) || "";
      this.popupVisibleObj[type].value = (v && v[0]?.value) || "";
      this.query[this.queryMap[type]] = (v && v[0]?.value) || "";
      this.onSearch();
    },
    onScroll(e) {
      if (this.scrollFrom === "detail") {
        this.scrollFrom = "list";
        return;
      }
      const dom = e.target;
      const offsetT = this.lastScrollTop - dom.scrollTop;
      const isTop = offsetT < 0;
      let v = this.scTop;
      if (isTop) {
        // 向上滚动
        if (Math.abs(offsetT) >= 52) {
          this.scTop = -104;
        }
      } else {
        this.scTop = 0;
      }
      this.timer && clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.lastScrollTop = dom.scrollTop;
      }, 20);
    },
    onLoad() {
      if (this.refreshing) {
        this.alarmRecords = [];
        this.refreshing = false;
      }
      this.getList();
    },
    selectCon(index, item, i) {
      item.isActive = !item.isActive;
      this.activeIndex = index;
      this.popupVisibleObj[index].visible = true;
    },
    getList() {
      this.finished = false;
      this.isFirstSearch = false;
      const params = Object.assign({}, this.query);
      getPageLocationAlarm(params)
        .then(data => {
          this.loading = false;
          this.total = data.total;
          if (data.list && data.list.length) {
            this.alarmRecords = this.alarmRecords.concat(data.list);
            this.query.page++;
          } else {
            this.finished = true;
          }
        })
        .catch(err => {
          this.loading = false;
          this.finished = false;
          this.loadError = true;
        });
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      // 将 page 重新设置为 1
      this.query.page = 1;
      this.onLoad();
    },
    gotoAlarmDetail(id) {
      this.$router.push({ name: "PositionAlarmDetail", params: { id } });
    },
    onSearch() {
      this.finished = false;
      this.loading = true;
      this.query.page = 1;
      this.alarmRecords = [];
      this.getList();
    },
    onCancel() {
      this.finished = false;
      this.handleCondition();
    },
    onClickLeft() {
      history.go(-1);
    },
    /**
     * 获取筛选条件
     */
    getSearchCondition() {
      getSelectLocationPersonType().then(res => {
        this.popupVisibleObj[1].list = res;
      });
      getSelectLocationAlarmType().then(res => {
        this.popupVisibleObj[2].list = res;
      });
      getSelectLocationAlarmStatus().then(res => {
        this.popupVisibleObj[3].list = res;
      });
      getSelectLocationDealStatus().then(res => {
        this.popupVisibleObj[4].list = res;
      });
    },
    /**
     * 筛选条件确认调用
     * @returns {Promise<void>}
     */
    async handleCondition() {
      this.query.page = 1;
      this.alarmRecords = [];
      this.loading = true;
      this.getList();
    }
  },
  activated() {
    this.$nextTick(() => {
      if (this.$refs.vanList && this.$refs.vanList.$el) {
        this.$refs.vanList.$el.scrollTo(0, this.scrollHeight || 0);
      }
    });
    if (this.needRefreshList) {
      this.onSearch();
      this.setNeedRefreshList({ value: false });
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.name === "PositionAlarmDetail") {
        vm.scrollFrom = "detail";
      } else {
        vm.scrollFrom = "list";
      }
    });
  },
  beforeRouteLeave(to, from, next) {
    this.scrollHeight = this.$refs.vanList.$el.scrollTop;
    next();
  },
  mounted() {
    this.getSearchCondition();
  }
};
</script>

<style scoped lang="scss">
.position-alarm {
  position: relative;
  text-align: left;
  background: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.alarm-top {
  position: relative;

  .van-nav-bar {
    z-index: 12;
  }

  .search-bar {
    padding-bottom: 0;
  }

  .filter-box-wrapper {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    transition: all 0.3s;
    z-index: 10;
  }

  .filter-box {
    padding: 5px 0;
    font-size: 12px;
    white-space: nowrap;
    overflow-x: scroll;
    border-bottom: 1px solid #c7c9d0;

    &::-webkit-scrollbar {
      display: none;
    }

    .filter-item {
      display: inline-block;
      border-radius: 10px;
      background-color: #eaeff5;
      padding: 4px 6px;
      box-sizing: border-box;
      margin: 0 4px;
      max-width: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #3b4664;

      &.select {
        position: relative;
        padding-right: 18px;

        &::after {
          position: absolute;
          content: "";
          right: 6px;
          top: 8px;
          border: 4px solid transparent;
          border-top-color: #aeb3c0;
        }

        &.active {
          &::after {
            border-top-color: $--color-primary;
          }
        }
      }

      &.active {
        background-color: #dcecff;
        color: $--color-primary;
      }

      &:first-child {
        margin-left: 16px;
      }

      &:last-child {
        margin-right: 16px;
      }
    }
  }

  .search-condition {
    display: flex;
    // margin: 1vw 5.6vw 3vw 5.6vw;
    justify-content: center;

    .loading {
      display: inline-block;
    }

    .van-loading__spinner {
      width: 4vw;
      height: 4vw;
    }

    .alarm-total {
      color: #8c8f97;
      text-align: center;
      font-size: 12px;
      padding: 12px 0 0;
    }

    .condition-switch {
      color: $--color-icon;

      i {
        font-size: 5vw;
      }
    }
  }
}

.alarm-search {
  // position: relative;
  margin: 0;
  // height: 14vh;
  .date-time-box {
    padding: 12px 16px 0 16px;
    background-color: #fff;

    .datetime {
      position: relative;
      margin: 0;
    }
  }
}

.alarm-list {
  padding-top: 100px;
  flex: 1;
  overflow-y: auto;
  z-index: 9;
}

.alarm-item {
  padding: 11px 17px 13px 16px;
  border-bottom: 8px solid #eceef2;
  font-size: 4vw;

  .alarm-title {
    font-size: 14px;
    font-weight: 500;
    color: #2e2e4d;
    line-height: 20px;

    .alarm-status {
      display: inline-block;
      margin-left: 4px;
      width: 52px;
      text-align: center;
      height: 16px;
      line-height: 16px;
      border-radius: 2px;
      font-size: 11px;
      font-weight: 400;
      color: #ffffff;
    }
    .warning {
      background: #fb7171;
    }
    .end {
      background: #eceef2;
      color: #8c8f97;
    }
  }

  .alarm-content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .alarm-content_right {
      .iconfont {
        margin-right: 10px;
      }

      .iconfont-daichuzhi {
        color: #3683f4;
        font-size: 44px;
      }

      .iconfont-yichuzhi {
        font-size: 46px;
        color: #8eb433;
      }
    }

    .alarm-content_left {
      .iconfont {
        display: inline-block;
        margin-right: 4px;
        font-size: 16px;
        color: #aeb3c0;
      }

      span {
        font-size: 12px;
        font-weight: 400;
        color: #a0a2a6;
      }

      .alarm-content_left-top {
        margin-bottom: 5px;
        display: flex;

        div {
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }

        div:first-of-type {
          margin-right: 20px;
        }
      }
    }
  }
}
</style>
